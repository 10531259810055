import React, { useState } from "react";
import CustomForm from "../../Common/Forms/form.component";
import Alert from "../../Common/Alerts/Alert.component";
import { post } from "../../../services/API";




import {CircularProgress, Snackbar} from "@mui/material";
import {useNavigate} from "react-router-dom";
import CustomTextField from "../../Common/Forms/Inputs/TextField/TextField.component";
import CustomButton from "../../Common/Button/Button.component";
import {useSelector} from "react-redux";
import CustomAddressFinder from "../../Common/AddressFinder/addressFinder.component";

export default function InstallerInfoForm({toggleFormHandle, handleOpen, userType}) {
    const [installerDetails, setInstallerDetails] = useState();
    const [installerAccountDetails, setInstallerAccountDetails] = useState({
        account_title: '',
        account_number: '',
        account_sort: '',
    });
    const [profileUpdated, setProfileUpdated] = useState(false);
    const [profileChangesMade, setProfileChangesMade] = useState(false);
    const [installerAddress, setInstallerAddress] = useState({});

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const navigate = useNavigate()


    const submitHandler = async (obj) => {
        setLoading(true)
        const body = {...obj, "user_type":userType}
        try {
            const res = await post("auth", "register", body);
            if (res.data) {
                setLoading(false)
                handleOpen('Registration successful, Please Sign in now', 'success')
                toggleFormHandle()

            }
            if (res.error) {
                setError(true);
                console.log(res.error);
                setErrorMessage(res.error.message);
                setLoading(false)
            }
        } catch (error) {
            console.error("An error occurred:", error);
            setLoading(false)
        }
    };

    const handleProfileFieldChange = (fieldName, value) => {
        const updatedProfile = {...installerDetails};
        updatedProfile[fieldName] = value;
        setInstallerDetails(updatedProfile);
        setProfileUpdated(false)

        setProfileChangesMade(true);
    };
    const handleAddressSave = async (id) => {
        setLoading(true)

        // Log the updated resident_details call update resident
        const obj = {
            'property_id': id,
            'installer_id': 1,

        };

        // const res = await post("users", "updateInstaller", obj);
        // if (res.data) {
        //     handleOpen('Registration successful, Please Sign in now', 'success')
        // }
        // if (res.error) {
        //
        //     setError(true);
        //
        // }
        // setLoading(false)

    };


    return (
        <>
            {      error && <Alert type="error" message={errorMessage ? errorMessage : 'Something went wrong please try after sometime!'} />
            }

                <div className="grid-equal-2">
                    <div>
                        <div>
                            <p className={"title-small emerald-font"}> Company Name</p>
                            <CustomTextField
                                value={installerDetails.company_name}
                                autocomplete={false}

                                inputStyles='var(--emerald-color)'
                                onChange={(e) => {
                                    handleProfileFieldChange('company_name', e.target.value);
                                }}/>
                        </div>
                        <div>

                            <p className={"title-small emerald-font"}>Email</p>
                            <CustomTextField
                                value={installerDetails.email}
                                autocomplete={false}

                                inputStyles='var(--emerald-color)'
                                onChange={(e) => {
                                    handleProfileFieldChange('email', e.target.value);
                                }}/>
                        </div>
                    </div>

                    <div>

                        <div>
                            <p className={"title-small emerald-font"}> Contact Name</p>
                            <CustomTextField
                                value={installerDetails.contact_name}
                                inputStyles='var(--emerald-color)'
                                autocomplete={false}

                                onChange={(e) => {
                                    handleProfileFieldChange('contact_name', e.target.value);
                                }}/>
                        </div>
                        <div>
                            <p className={"title-small emerald-font"}>Phone Number</p>
                            <CustomTextField
                                value={installerDetails.contact_number}
                                autocomplete={false}
                                inputStyles='var(--emerald-color)'
                                onChange={(e) => {
                                    const inputValue = e.target.value ? parseInt(e.target.value.replace(/^\+44/, '')) : "";
                                    handleProfileFieldChange('contact_number', inputValue);
                                }}
                                type="text"/>
                        </div>
                    </div>

                    <div>
                        <p className={"title-small emerald-font"}>MCS Number</p>
                        <CustomTextField
                            value={installerDetails.mcs_number}
                            autocomplete={false}
                            inputStyles='var(--emerald-color)'
                            onChange={(e) => {
                                handleProfileFieldChange('mcs_number', e.target.value);
                            }}
                            type="text"/>
                    </div>

                    <div>
                        <p className={"title-small emerald-font"}>Website</p>
                        <CustomTextField
                            value={installerDetails.website_link}
                            autocomplete={false}
                            inputStyles='var(--emerald-color)'
                            onChange={(e) => {
                                handleProfileFieldChange('website_link', e.target.value);
                            }}
                        />
                    </div>

                </div>
                <div>
                    <p className={"title-small emerald-font"}>Company Bio</p>
                    <CustomTextField
                        multiline
                        value={installerDetails.company_bio}
                        autocomplete={false}
                        inputStyles='var(--emerald-color)'
                        onChange={(e) => {
                            handleProfileFieldChange('company_bio', e.target.value);
                        }}
                    />
                </div>
            <div>
                <CustomAddressFinder residentJourneyUpdate={handleAddressSave}
                                     setResidentAddress={setInstallerAddress}/>
            </div>
                {/*<div className="flex-row  justify-end">*/}
                {/*    <CustomButton*/}
                {/*        variant="outlined"*/}
                {/*        small={true}*/}
                {/*        cssType={profileUpdated ? "primary" : "secondary"}*/}
                {/*        label={profileUpdated ? "Saved" : "Save"}*/}
                {/*        onClick={handleProfileSave}*/}
                {/*        disabled={!profileChangesMade}/>*/}
                {/*</div>*/}


            {loading && <CircularProgress color="secondary" />}
        </>
    );
}

