import {SET_CURRENT_LEAD, SET_NAVIGATION_FROM} from "../Types/calender.type";

export const setNavigationFrom = (from) => ({
    type: SET_NAVIGATION_FROM,
    payload: from
});

export const setCurrentLead = (lead) => ({
    type: SET_CURRENT_LEAD,
    payload: lead
});