import React from 'react';
import "./card.style.css";

export default function CustomCard(props) {
    const { value, handleOnClick, title, description, type, image } = props;
    const localServer = process.env.REACT_APP_SERVER_URL;

    return (

        <div key={`card-${value}`} className={"card-container"} onClick={() => handleOnClick(value)} >
            {
                type === "standard" && image ?
                    <div className="card-image">
                        <img src={`${localServer}/images/${image}.svg`} style={{ height: '100%' }} alt={`Descriptive illustration for ${title}`}></img>
                    </div>
                    : null
            }

            <div className={'card-text'}>
                {
                    type === "standard"
                        ? <h1 className={"title-medium emerald-font option-heading"}  >{title}</h1>
                        : <h1 className={"text-standard grey-font option-heading-mini"}  >{title}</h1>
                }
                {/* option description */}
                {
                    type === "standard" && <div className={"text-small grey-font option-description"} > {description} </div>

                }
            </div>



        </div>




    );
};

