import { combineReducers } from 'redux' 

//Importing the required reducers 
import formReducer from "./forms.reducer" 
import hpaReducer from "./hpAssessment.reducer"
import userReducer from './user.reducer'
import calenderReducer from "./calender.reducer";


const rootReducer = combineReducers({
  formReducer:formReducer,
  hpaReducer:hpaReducer,
  userReducer:userReducer,
  calenderReducer:calenderReducer


})
export default rootReducer