import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { customMuiTheme } from './styles/muiTheme'
import AppRoutes from "./routes";
import { AuthProvider } from "./auth/AuthProvider";
import './App.css';

import ReactGA from 'react-ga4';
const TRACKING_ID = "G-B1M8EVKGZJ";

export default function App() {

    // Initialise Google Analytics tracking with react-ga4
    useEffect(() => {
        ReactGA.initialize(TRACKING_ID);
        // Send pageview with a custom path
        ReactGA.send({ hitType: "pageview", page: "/", title: "Landing Page" });
    }, []);

    return (

        <div className="App">

            <ThemeProvider theme={customMuiTheme}>
                <BrowserRouter>
                    <AuthProvider>
                        <AppRoutes />
                    </AuthProvider>
                </BrowserRouter>
            </ThemeProvider>

        </div>
    );
};
