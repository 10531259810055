
import "./Button.style.css"
import {Button} from "@mui/material";


export default function CustomButton (props) {
    const {cssType, label, icon, small, ...rest } = props
    const buttonProps = {
        className: `${small ? 'btn-small' : 'btn'} ${cssType}` ,
        label,
        ...rest
    }

    return (
        <button { ...buttonProps}>
            <div className={"btn-label"}>
                <div className=  {`${small ? 'text-small' : 'text-standard'}`}>
                    {label}
                </div>

                {
                    icon &&
                    <div style={{marginLeft: "3px", display:"flex"}}>
                        {icon}
                    </div>
                }

            </div>
        </button>

    )
};