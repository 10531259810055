import { createTheme  } from '@mui/material/styles';

export const customMuiTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#14596b',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#f26548',
            contrastText: '#ffffff',
        },
        white: {
            main: '#ffffff',
            contrastText: '#ffffff',
        },
        background: {
            default: '#EAF4F2',
        },
    }
});