export const residentSignUpFormData = [
    {
        "id": "first_name",
        "question_number": "1",
        "label": "First Name",
        "question_description": "",
        "question_type": "text",
        "data_type": "text",
        "child": false,
        "parent_id": null,
        "parent_value": null,
        "disabled": false,
        "autocomplete": false,
        "thermly": false,
        "thermly_text": "",
        "thermly_image": null,
        "options": null,
        "validation": [
            {
                "validation_id": 78,
                "validation_type": "text",
                "validation_parameter": "",
                "validation_message": "Please enter a valid name"
            },
            {
                "validation_id": 79,
                "validation_type": "required",
                "validation_parameter": "",
                "validation_message": "Please enter your name"
            }
        ],
        "image": "",
        "value": ""
    },
    {
        "id": "last_name",
        "question_number": "2",
        "label": "Last Name",
        "question_description": "",
        "question_type": "text",
        "data_type": "text",
        "child": false,
        "parent_id": null,
        "parent_value": null,
        "disabled": false,
        "autocomplete": false,
        "thermly": false,
        "thermly_text": "",
        "thermly_image": null,
        "options": null,
        "validation": [
            {
                "validation_id": 78,
                "validation_type": "text",
                "validation_parameter": "",
                "validation_message": "Please enter a valid name"
            },
            {
                "validation_id": 79,
                "validation_type": "required",
                "validation_parameter": "",
                "validation_message": "Please enter your name"
            }
        ],
        "image": "",
        "value": ""
    },
    {
        "id": "email",
        "question_number": "3",
        "label": "Email Address",
        "question_description": "",
        "question_type": "text",
        "data_type": "text",
        "child": false,
        "parent_id": null,
        "parent_value": null,
        "disabled": false,
        "autocomplete": false,
        "thermly": false,
        "thermly_text": "",
        "thermly_image": null,
        "options": null,
        "validation": [
            {
                "validation_id": 78,
                "validation_type": "email",
                "validation_parameter": "",
                "validation_message": "Please enter a valid email address"
            },
            {
                "validation_id": 79,
                "validation_type": "required",
                "validation_parameter": "",
                "validation_message": "Please enter your email"
            }
        ],
        "image": "",
        "value": ""
    },
    {
        "id": "password",
        "question_number": "4",
        "label": "Password",
        "question_description": "",
        "question_type": "password",
        "data_type": "text",
        "child": false,
        "parent_id": null,
        "parent_value": null,
        "disabled": false,
        "autocomplete": false,
        "thermly": false,
        "thermly_text": "",
        "thermly_image": null,
        "options": null,
        "validation": [
            {
                "validation_id": 78,
                "validation_type": "string",
                "validation_parameter": "",
                "validation_message": "Please enter a valid password"
            },
            {
                "validation_id": 79,
                "validation_type": "required",
                "validation_parameter": "",
                "validation_message": "Please enter your  password"
            },
            {
                "validation_id": 83,
                "validation_type": "matches",
                "validation_parameter": "^(?=.*[A-Z])(?=.*[0-9]).{8,}$",
                "validation_message": "Password must meet the following criteria:\n- At least 8 characters long\n- Contains at least one capital letter\n- Contains at least one number"
            }
        ],
        "image": "",
        "value": ""
    }
]

export const installerSignUpFormData = [
    {
        "id": "contact_name",
        "question_number": "1",
        "label": "Contact Name",
        "question_description": "",
        "question_type": "text",
        "data_type": "text",
        "child": false,
        "parent_id": null,
        "parent_value": null,
        "disabled": false,
        "autocomplete": false,
        "thermly": false,
        "thermly_text": "",
        "thermly_image": null,
        "options": null,
        "validation": [
            {
                "validation_id": 78,
                "validation_type": "text",
                "validation_parameter": "",
                "validation_message": "Please enter a valid name"
            },
            {
                "validation_id": 79,
                "validation_type": "required",
                "validation_parameter": "",
                "validation_message": "Please enter your name"
            }
        ],
        "image": "",
        "value": ""
    },
    {
        "id": "company_name",
        "question_number": "2",
        "label": "Company Name",
        "question_description": "",
        "question_type": "text",
        "data_type": "text",
        "child": false,
        "parent_id": null,
        "parent_value": null,
        "disabled": false,
        "autocomplete": false,
        "thermly": false,
        "thermly_text": "",
        "thermly_image": null,
        "options": null,
        "validation": [
            {
                "validation_id": 78,
                "validation_type": "text",
                "validation_parameter": "",
                "validation_message": "Please enter a valid name"
            },
            {
                "validation_id": 79,
                "validation_type": "required",
                "validation_parameter": "",
                "validation_message": "Please enter your name"
            }
        ],
        "image": "",
        "value": ""
    },
    {
        "id": "email",
        "question_number": "3",
        "label": "Email Address",
        "question_description": "",
        "question_type": "text",
        "data_type": "text",
        "child": false,
        "parent_id": null,
        "parent_value": null,
        "disabled": false,
        "autocomplete": false,
        "thermly": false,
        "thermly_text": "",
        "thermly_image": null,
        "options": null,
        "validation": [
            {
                "validation_id": 78,
                "validation_type": "email",
                "validation_parameter": "",
                "validation_message": "Please enter a valid email address"
            },
            {
                "validation_id": 79,
                "validation_type": "required",
                "validation_parameter": "",
                "validation_message": "Please enter your email"
            }
        ],
        "image": "",
        "value": ""
    },
    {
        "id": "password",
        "question_number": "4",
        "label": "Password",
        "question_description": "",
        "question_type": "password",
        "data_type": "text",
        "child": false,
        "parent_id": null,
        "parent_value": null,
        "disabled": false,
        "autocomplete": false,
        "thermly": false,
        "thermly_text": "",
        "thermly_image": null,
        "options": null,
        "validation": [
            {
                "validation_id": 78,
                "validation_type": "string",
                "validation_parameter": "",
                "validation_message": "Please enter a valid password"
            },
            {
                "validation_id": 79,
                "validation_type": "required",
                "validation_parameter": "",
                "validation_message": "Please enter your  password"
            },
            {
                "validation_id": 83,
                "validation_type": "matches",
                "validation_parameter": "^(?=.*[A-Z])(?=.*[0-9]).{8,}$",
                "validation_message": "Password must meet the following criteria:\n- At least 8 characters long\n- Contains at least one capital letter\n- Contains at least one number"
            }
        ],
        "image": "",
        "value": ""
    }
]