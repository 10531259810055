import "./AppLayout.styles.css"
import AppHeader from "./AppHeader.component";
import AppFooter from "./AppFooter.component";
export default function AppLayout({ menu, installer, children, withLogin}) {

    return (
        <>
            <div className="app-container">
                <div className="app-content-wrapper">
                    <AppHeader menu={menu} withLogin={withLogin} installer={installer}/>
                    {children}
                </div>
                <AppFooter />

            </div>
        </>

    )
};