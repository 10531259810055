import {lazy, Suspense} from 'react';
import {Route, Routes} from "react-router-dom";

import LoadingWithBackdropComponent from "./components/Common/Loader/LoadingWithBackdrop.component";
import AuthenticationGuard from "./auth/AuthGuard";
import Installer from "./pages/Installer/Installer";
import Resident from "./pages/Resident/Resident";
import {CallbackPage} from "./pages/Callback";
import InstallerAuthPage from "./pages/Login/InstallerAuthPage";

const AddressLookup = lazy(() => import('./pages/ReadinessAssessment/AddressLookup/addressLookup'));
const HeatPumpReadiness = lazy(() => import('./pages/ReadinessAssessment/heatpumpReadiness'));
const HeatPumpReadinessAssessment = lazy(() => import('./pages/ReadinessAssessment/HeatPumpReadinessAssessment/heatPumpReadinessAssessment'));
const AppLayout = lazy(() => import('./components/Layouts/AppLayout.component'));
const HeatPumpReport = lazy(() => import('./pages/ReadinessAssessment/HeatPumpReadinessReport/heatPumpReport'));
const AuthPage = lazy(() => import('./pages/Login/AuthPage'));
const PasswordChange = lazy(() => import('./pages/Login/PasswordChange'));
const AccountVerification = lazy(() => import('./pages/Login/AccountVerification'));

const DataCapture = lazy(() => import('./pages/Resident/DetailedDataCapture/detailDataCapture'));
const ResidentHome = lazy(() => import('./pages/Resident/ResidentHome/residentHome'));
const InstallerComparison = lazy(() => import('./pages/Resident/InstallerComparison/InstallerComparison'));
const BookingScreen = lazy(() => import('./pages/Resident/Booking/Booking'));
const LeadManagement = lazy(() => import('./pages/Installer/LeadManagement/leadManagement'));
const CurrentOrders = lazy(() => import('./pages/Installer/CurrentOrders/CurrentOrders'));
const QualifiedLeads = lazy(() => import('./pages/Installer/QualifiedLeads/QualifiedLeads'));
const InstallerHome = lazy(() => import('./pages/Installer/InstallerHome/InstallerHome'));
const InstallerProfile = lazy(() => import('./pages/Installer/InstallerProfile/InstallerProfile'));
const ResidentProfile = lazy(() => import('./pages/Resident/ResidentProfile/ResidentProfile'));
const Profile = lazy(() => import('./components/Profile/Profile.component'));
const Calendar = lazy(() => import('./components/Calender/Calendar'));
const Payments = lazy(() => import('./pages/Resident/Payments/Payment'));
const FinancePayments = lazy(()=> import('./pages/Resident/Payments/FinancePayments/financePayments'))

const AppRoutes = () => {
    return (
        <Suspense fallback={<LoadingWithBackdropComponent/> }>
            <Routes>
                <Route exact path="/" element={<AddressLookup />} />
                <Route path="address-lookup" element={<AddressLookup />} />
                <Route path="heatpump-readiness" element={<HeatPumpReadiness />} />
                <Route path="heatpump-readiness-assesment" element={<HeatPumpReadinessAssessment />} />
                <Route path="heatpump-report" element={<HeatPumpReport/>} />
                <Route path="resident-auth" element={<AuthPage userType={'resident'}/>} />
                <Route path="installer-auth" element={<AuthPage userType={'installer'} />} />
                <Route path="verify-account/:userType/:type/:token/:state" element={<AccountVerification />} />

                <Route path="installer-info" element={<InstallerAuthPage userType={'installer'} />} />
                <Route path="password-change/:userType/:token" element={<PasswordChange/>} />
                <Route path="profile" element={<Profile />} />
                <Route path="calender" element={<AppLayout><Calendar/></AppLayout>} />
                <Route path="callback" element={<CallbackPage />} />

                {/*Resident Routes*/}
                <Route path={"/resident"} element={<Resident />}>
                    <Route path="data-capture/:headingParam/:formState?" element={<AuthenticationGuard component={DataCapture} userType={'resident'} />} />
                    <Route path="resident-home" element={<AuthenticationGuard component={ResidentHome} userType={'resident'} />} />
                    <Route path="profile" element={<AuthenticationGuard component={ResidentProfile} userType={'resident'} />} />
                    <Route path="booking-screen/:type" element={<AuthenticationGuard component= {BookingScreen}userType={'resident'}  />} />
                    <Route path="payments-finance" element={<AuthenticationGuard component={FinancePayments} userType={'resident'}  />} />
                    <Route path="payments" element={<AuthenticationGuard component={Payments} userType={'resident'}  />} />
                    <Route path="installer-comparison" element={<AuthenticationGuard component={InstallerComparison} userType={'resident'} />} />
                </Route>

                {/*Installer Routes*/}
                <Route path={"/installer"} element={<Installer/>}>
                    <Route path="lead-management" element={<AuthenticationGuard component={LeadManagement} userType={'installer'} />}  />
                    <Route path="qualified-leads" element={<AuthenticationGuard component={QualifiedLeads}  userType={'installer'}/>}  />
                    <Route path="current-orders" element={<AuthenticationGuard component={CurrentOrders}  userType={'installer'}/>}  />
                    <Route path="profile" element={<AuthenticationGuard component={InstallerProfile}  userType={'installer'}/>}  />
                    <Route path="home" element={<AuthenticationGuard component={InstallerHome}  userType={'installer'}/>}  />
                </Route>
            </Routes>
        </Suspense>
    )
}

export default AppRoutes;