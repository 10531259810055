import { Worker, Viewer } from "@react-pdf-viewer/core";

import React, { useState, useRef } from 'react';

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const PdfViewer = ({ pdfUrl,setIsScrolled }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [numPages, setNumPages] = useState(0);
  const viewerRef = useRef(null);

  const handlePageChange = (page) => {
      setCurrentPage(page.currentPage + 1);
      if(currentPage+1 === numPages && setIsScrolled){
        setIsScrolled(true)
      }
      // else{
      //   setIsScrolled && setIsScrolled(false)
      // }
  };

  const handleDocumentLoad = (documentLoadEvent) => {
    const  numPages  = documentLoadEvent.doc._pdfInfo.numPages;
    setNumPages(numPages);
};

  return (
    <>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      <div style={{ position: "relative", overflowY: "auto" }}>
        <Viewer
          fileUrl={pdfUrl}
          onPageChange={handlePageChange}
          onDocumentLoad={handleDocumentLoad}
          ref={viewerRef}
          hideToolbar={true} // Hide the top bar
          hideSidebar={true} // Hide the side bar
        />
       
      </div>
    </Worker>
 
    </>
  
  );
};

export default PdfViewer;
