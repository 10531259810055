import React from 'react';
import ReactDOM from 'react-dom/client';

// Importing redux dependencies
import { Provider } from "react-redux"
import Store from "./redux/store"
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {registerLicense} from "@syncfusion/ej2-base";

registerLicense('ORg4AjUWIQA/Gnt2V1hhQlJAfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5XdEZjXH9ZdHdTRWNb');

const root = ReactDOM.createRoot(document.getElementById('root'));
const { store} = Store

root.render(
    <Provider store={store}>
            <App />
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals