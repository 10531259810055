import React, { useEffect, useState, useRef } from "react";
import { Alert, Badge, Fade, FormControlLabel, FormGroup, IconButton, Popper, Stack, Switch } from "@mui/material";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CheckIcon from "@mui/icons-material/Check";
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import { icons } from "../../utils/Icons";
import { getSingle, post } from "../../services/API";
import "./Notification.style.css";
import Divider from "@mui/material/Divider";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentLead, setNavigationFrom } from "../../redux/Actions/calender.action";
import { useNavigate } from "react-router-dom";

export default function NotificationCenter(props) {
    const [showUnreadOnly, setShowUnreadOnly] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const popupRef = useRef();
    const leads = useSelector((state) => state.userReducer.leads);
    const ws = useRef(null); // WebSocket reference

    useEffect(() => {
        ws.current = new WebSocket('ws://localhost:3000');
        ws.current.onopen = () => {
            console.log('WebSocket connected');
        };
        ws.current.onmessage = (event) => {
            const newNotification = JSON.parse(event.data);
            setNotifications((prevNotifications) => [...prevNotifications, newNotification]);
        };
        getNotifications()

        return () => {
            if (ws.current) {
                ws.current.close();
            }
        };
    }, []);

    const getNotifications = async () => {
        // Fetch notifications from API
        const parameter = props.user.mcs_number ? `getAllNotifications/false` : `getAllNotifications/true`;
        const res = await getSingle("notifications", parameter);
        if (res.data) {
            setNotifications(res.data);
        }
    };

    const toggleNotificationCenter = (event) => {
        setAnchorEl(event.currentTarget);
        setIsOpen(!isOpen);
        getNotifications();
    };
    const toggleFilter = () => {
        setShowUnreadOnly(!showUnreadOnly);
    };

    const markAsRead = async (clickedNotification) => {
        try {
            const updatedNotifications = notifications.map((notification) =>
                notification.notification_id === clickedNotification.notification_id ? {
                    ...notification,
                    isread: true
                } : notification
            );
            setNotifications(updatedNotifications);
            await markNotificationRead(clickedNotification);
        } catch (error) {
            console.error("Error marking notification as read:", error);
        }
    };

    const markAllAsRead = async () => {
        const updatedNotifications = notifications.map((notification) => ({
            ...notification,
            isread: true
        }));
        await markNotificationRead(updatedNotifications);
        setNotifications(updatedNotifications);
    };

    const markNotificationRead = async (updatedNotifications) => {
        try {
            const parameter = props.user.mcs_number ? `updateNotification/false` : `updateNotification/true`;
            const res = await post("notifications", parameter, updatedNotifications);
            if (res.error) {
                throw new Error(res.error.message);
            }
        } catch (error) {
            console.error("Error marking notifications as read:", error);
        }
    };

    const notificationAction = (notification) => {
        if (notification && notification.type.includes('installer_slots_requested')) {
            dispatch(setNavigationFrom('/notifications'));
            dispatch(setCurrentLead(leads.find(lead => lead.resident_id == notification.resident_id)));
            markAsRead(notification);
            navigate('/installer/qualified-leads');
        } else {
            markAsRead(notification);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target) && anchorEl && !anchorEl.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen, anchorEl]);

    return (
        <div>
            <IconButton size="large" disableRipple={true} onClick={toggleNotificationCenter} style={{ color: 'black' }}>
                <Badge badgeContent={notifications && notifications.filter(n => !n.isread).length} color="primary">
                    {icons["notifications"]}
                </Badge>
            </IconButton>

            <Popper open={isOpen} anchorEl={anchorEl} transition ref={popupRef}>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <div className={"notification-center"}>
                            <div className={"flex-row justify-space-between notification-center-header"}>
                                <h1 className="title-medium emerald-font">
                                    Notification centre
                                </h1>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                color="secondary"
                                                onChange={toggleFilter}
                                                checked={showUnreadOnly}
                                            />
                                        }
                                        label="Show unread only"
                                    />
                                </FormGroup>
                            </div>
                            <Divider />
                            <Stack
                                sx={{
                                    height: "400px",
                                    width: "min(60ch, 100ch)",
                                    padding: "12px",
                                    overflowY: "auto",
                                }}
                                spacing={2}
                            >
                                {notifications && (!notifications.length ||
                                    (showUnreadOnly && notifications.every((notification) => notification.read))) && (
                                    <h1 className={"text-standard"}>
                                        Your queue is empty! You are all set{" "}
                                        <span role="img" aria-label="dunno what to put">🎉</span>
                                    </h1>
                                )}
                                {showUnreadOnly
                                    ? notifications && notifications.filter(notification => !notification.isread).map((notification) => (
                                    <Alert
                                        sx={{ alignItems: "center" }}
                                        key={notification.id}
                                        action={
                                            notification.isread ? (
                                                <CheckIcon />
                                            ) : (
                                                notification.type.includes('installer_slots_requested')
                                                    ? <IconButton
                                                        color="primary"
                                                        aria-label="mark as read"
                                                        onClick={() => notificationAction(notification)}>
                                                        <OpenInNewIcon />
                                                    </IconButton>
                                                    : <IconButton
                                                        color="primary"
                                                        aria-label="mark as read"
                                                        onClick={() => notificationAction(notification)}>
                                                        <MarkChatReadIcon />
                                                    </IconButton>
                                            )
                                        }
                                    >
                                        {notification.app_message}
                                    </Alert>
                                ))
                                    : notifications && notifications.map((notification) => (
                                    <Alert
                                        sx={{ alignItems: "center" }}
                                        key={notification.id}
                                        action={
                                            notification.isread ? (
                                                <CheckIcon />
                                            ) : (
                                                notification.type.includes('installer_slots_requested')
                                                    ? <IconButton
                                                        color="primary"
                                                        aria-label="mark as read"
                                                        onClick={() => notificationAction(notification)}>
                                                        <OpenInNewIcon />
                                                    </IconButton>
                                                    : <IconButton
                                                        color="primary"
                                                        aria-label="mark as read"
                                                        onClick={() => notificationAction(notification)}>
                                                        <MarkChatReadIcon />
                                                    </IconButton>
                                            )
                                        }
                                    >
                                        {notification.app_message}
                                    </Alert>
                                ))}
                            </Stack>
                            <div className={"flex-row justify-end"}>
                                <IconButton size="large" onClick={markAllAsRead} style={{ color: 'black' }}>
                                    {icons["read"]}
                                </IconButton>
                            </div>
                        </div>
                    </Fade>
                )}
            </Popper>
        </div>
    );
}
