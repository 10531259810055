import {SET_CURRENT_LEAD, SET_NAVIGATION_FROM} from "../Types/calender.type";

const initialState = {
    navigationFrom: null,
    currentLead: null

};

const calenderReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NAVIGATION_FROM:
            return {
                ...state,
                navigationFrom: action.payload
            };
        case SET_CURRENT_LEAD:
            return {
                ...state,
                currentLead: action.payload
            };
        default:
            return state;
    }
};

export default calenderReducer;
