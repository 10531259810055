import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomForm from "../../Common/Forms/form.component";
import useAuth from "../../../auth/AuthProvider";

import "./RegisterForm.styles.css"
import { CircularProgress, Snackbar } from "@mui/material";
import { post, getSingle } from "../../../services/API";
import { setFormValue } from "../../../redux/Actions/forms.action";
import { installerSignUpFormData, residentSignUpFormData } from "../RegisterForms";


export default function RegisterForm({ toggleFormHandle, setError, setErrorMessage, userType, setAlertMessage, setSignIn, setResendVerification, setSignUPformData}) {
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const submission_id = useSelector((state) => state.formReducer.hpAssesmentForm.submission_detail?.submission_id);
    const property_id = useSelector((state) => state.formReducer.addressForm.submission_detail?.property_id);
    const { user, error, login, signUp, logout } = useAuth();

    const submitHandler = async (obj) => {
        setLoading(true)
        const body = {
            ...obj,
            "user_type": userType,
            ...(userType === "resident" && { "temp_form_submission_id": submission_id || null }),
            ...(userType === "resident" && { "property_id": property_id || null })
        };
      
        setError(false)
        setErrorMessage('');
        const onSuccess = async (message, type, userData) => {
            // case 1 clear  hpr redux on registration sucess  if user is coming from hpr
            if (userType === "resident" && submission_id) {
                dispatch(setFormValue("SET_HP_ASSESMENT_Form", {}))
                dispatch(setFormValue("SET_FINAL_ADDRESS", {}));
            }
         

            setSignIn(true)
            setAlertMessage(message, type)
            setResendVerification(true)
            setSignUPformData([obj])
        };

        const onError = (errorMessage) => {
            setError(true)
            setErrorMessage(errorMessage);
        };
        await signUp(body, onSuccess, onError)
       
        setLoading(false)

    };

 
    return (
        <>
            <CustomForm
                autoSave={false}
                formData={userType === 'resident' ? residentSignUpFormData : installerSignUpFormData}
                small={true}
                noLines={true}
                button_position_css='flex-row-wrap justify-center'
                handleSubmit={submitHandler}
                handleAutoSave={() => { }}
                labelStyles="text-standard emerald-font"
                inputStyles='var(--emerald-color)'
                buttonLabel={"Sign up"}
                form_disabled={loading}
            />
            {loading && <CircularProgress color="secondary" />}
        </>
    );
}

