import * as yup from "yup";
import validator from 'validator';

const PasswordRegEx = /^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;

export const registerValidationSchema = yup.object({
  firstName: yup
    .string()
    .min(3, "Too Short !")
    .max(30, "Too Long !")
    .required("Required !"),
  lastName: yup
    .string()
    .min(3, "Too Short !")
    .max(30, "Too Long !")
    .required("Required !"),
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(8, 'Minimum 8 characters length')
    .required('Password is required'),
});

// export const dynamic

export const loginValidationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(8, 'Minimum 8 characters length')
    .required('Password is required'),
});




// dynamic validation schemas for forms
export const dynamicValidation = obj => yup.object().shape(obj)
export const transformValidationSchema = (question) => {
  let schema = yup.mixed(); // Start with a mixed schema, which allows any data type by default
  let hasRequired = false;
  let maxFiles = 1;
  const validation = question.validation;
  validation?.forEach(val => {
    const { validation_type, validation_message, validation_parameter } = val;
    switch (validation_type) {
      case 'required':
        hasRequired = true;
        break;
      case 'max':
        maxFiles = parseInt(validation_parameter);
        break;
    }
    switch (validation_type) {
      case 'array':
        schema = yup.array();
        break;
      case 'number':
        schema = yup.number().typeError();
        break;
        case 'email':
          schema = schema.test({
            name: 'isValidEmail',
            message: `*${validation_message}`,
            test: (value) => validator.isEmail(value),
          });
          break;
      case 'text':
      case 'string':
        schema = yup.string();
        break;
      case 'boolean':
        schema = yup.boolean().oneOf([true], `*${validation_message}`);
        break;
        case 'photo':
          // File check test type
          schema = schema.test('photoFileCheck', '*Please upload image in valid format (jpg, jpeg, png)', (value) => {
            if (!Array.isArray(value) && typeof value !== 'string') {
              return false; // Invalid format for photo question
            }
            // Additional checks for file extensions or other validations if needed
            return true;
          }); 
          
          // Length check test type
          schema = schema.test('photoLengthCheck', `*${validation[1].validation_message}`, (value) => {
            return value.length <= maxFiles; // Check against the maximum number of files allowed
          });
        
          // Required check test type
          schema = schema.test('photoRequiredCheck', `*${validation_message}`, (value) => {
            return !hasRequired || (value && value.length > 0); // Check if the photo is required and if the user has uploaded at least one file
          });
        
          break;  
    }

    // Applying additional validation rules to the schema for each question
    switch (validation_type) {
      case 'min':
        schema = schema.min(validation_parameter, `*${validation_message}`);
        break;
      case 'matches':
        schema = schema.matches(validation_parameter, `*${validation_message}`);
        break;
      case 'length':
        schema = schema.min(validation_parameter, `*${validation_message}`);
        break;
      case 'required':
        if (question.child) {
          if (question.parent_id && question.parent_value) {
            schema = schema.when(question.parent_id, {
              is: question.parent_value,
              then: (currentSchema) => currentSchema.required(`*${validation_message}`),
              otherwise: (currentSchema) => currentSchema,
            });
          } else {
            // Handle the case where parent_id or parent_value is missing
            schema = schema; 
          }
        } else {
          schema = schema.required(`*${validation_message}`);
        }
       
        break;
    }
 
  });          
  
  


  return schema;
};





