import { useState } from "react"
import "./Checkbox.style.css"
import HelperThermly from "../../../../HelperThermly/HelperThermly.component";

export default function CustomCheckbox(props) {
  const {
    question,
    onChange,
    onBlur,
    error,
    helperText,
    inputStyles,
    ...rest
  } = props;

  const [checked, setChecked] = useState(false);

  const handleOnChange = (event) => {
    const { checked } = event.target.checked;
    setChecked(checked);
    if (onChange) {
      onChange(event);
    }
  };
  return (
    <div className="checkbox" >
      <div className="checkbox-container">
        <input type="checkbox"
          className="checkbox-input"
          id={question.id}
          onBlur={onBlur}
          value={checked}
          checked={checked}
          onChange={handleOnChange}
          {...rest}
        />
        <label className="checkbox-custom" style={{
          border: question.id !== 'terms_and_conditions' ? '1px solid var(--emerald-color)' : '1px solid white'
        }} htmlFor={question.id}></label>
        {/* checkbox label */}

        <div className={`text-small checkbox-label ${question.id !== 'terms_and_conditions' ? 'grey-font' : 'white-font'}`} dangerouslySetInnerHTML={{ __html: question.label }}>

        </div>
        {question.thermly && <HelperThermly thermly_text={question.thermly_text} thermly_image={question.thermly_image} inputStyles={inputStyles}
        />
        }

      </div>
      {/* errors */}
      <p className="text-small orange-font"> {helperText} </p>

    </div>
  );
}