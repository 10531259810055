import { useSelector } from "react-redux"
import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"

// Custom components
import { AddressFinder } from "@ideal-postcodes/address-finder"
import CustomForm from "../../../components/Common/Forms/form.component"
import LoadingWithBackdropComponent from "../../../components/Common/Loader/LoadingWithBackdrop.component"
import Alert from "../../../components/Common/Alerts/Alert.component"
import {
    getFormdataPending,
    getFormdataSuccess,
    getFormdataError,
} from "../../../redux/Actions/forms.action"

//services and styles
import { getSingle, post } from "../../../services/API"
import "../../../pages/ReadinessAssessment/AddressLookup/addressLookup.styles.css"

import CustomTextField from "../../../components/Common/Forms/Inputs/TextField/TextField.component"
import axios from "axios";



export default function CustomAddressFinder({residentJourneyUpdate,setResidentAddress}) {
    const dispatch = useDispatch()
    const [formData, setformData] = useState([]);
    const loading = useSelector((state) => state.formReducer.loading)
    const error = useSelector((state) => state.formReducer.error)
    const message = useSelector((state) => state.formReducer.errorMessage)
    const [isManualEntry, setisManualEntry] = useState(false);
    const [formKey, setFormKey] = useState(0);

    // State variables for the address lookup fields
    const [address, setAddress] = useState({
        address_lookup_line_1: "",
        address_lookup_line_2: "",
        address_lookup_line_3: "",
        address_lookup_post_town: "",
        address_lookup_postcode: "",
        address_lookup_uprn: null,
    })

    // Fetch addressLookup form data
    const getaddressForm = async () => {
        dispatch(getFormdataPending())
        const url = "form/request"
        const res = await getSingle(url, "address_lookup")
        if (res.data) {
            // Set form data in the redux
            const formData = res.data.sort((a, b) => a.question_number - b.question_number);
            formData.pop()
            setformData(formData)
            dispatch(getFormdataSuccess(formData, "address_lookup"))
            
        }
        if (res.error) {
            // set error in the redux
            dispatch(getFormdataError({ error: true, errorMessage: res.error.message }))
        }
    }

    //submit handler
    const handleSubmit = async (values, { setSubmitting }) => {
        let finalAddress = {
            ...values,
            address_lookup_uprn: address.address_lookup_uprn,
        }
        // api call
        const result = await post("users", "createProperty", [
            finalAddress.address_lookup_uprn,
            finalAddress.address_lookup_line_1,
            finalAddress.address_lookup_line_2,
            finalAddress.address_lookup_line_3,
            finalAddress.address_lookup_post_town,
            finalAddress.address_lookup_postcode,
            finalAddress.address_lookup_postcode.replace(/\s/g, "")
        ])
        finalAddress = { address: { ...finalAddress }, submission_detail: { property_id: result.data } };
        // dispatches a Redux action to set the address lookup values
        setSubmitting(false)
        residentJourneyUpdate && residentJourneyUpdate(result.data)
        setResidentAddress && setResidentAddress(finalAddress.address)
    }

    // Setting up address finder and fetching the address
    const fetchAddress = () => {
        axios.defaults.withCredentials = false;
        AddressFinder.setup({
            containerStyle: {
                fontFamily: "Sofia Pro Soft",

            },
            mainStyle: {
                boxShadow: 'none'
            },

            inputField: "#addressInput",
            outputFields: {
                line_1: "#address_lookup_line_1",
                line_2: "#address_lookup_line_2",
                line_3: "#address_lookup_line_3",
                post_town: "#address_lookup_post_town",
                postcode: "#address_lookup_postcode"
            },
            // test api key
            apiKey: window.apiKey || "ak_test",
            autocomplete: "off",
            injectStyle: true,
            onAddressRetrieved: ({ line_1, line_2, line_3, post_town, postcode, uprn }) => {
                // document.querySelector(".idpc-unhide").style.display = "none";
                const addressForms = document.querySelectorAll(".addressForm");
                addressForms.forEach((el) => {
                    el.classList.toggle("hidden");
                });
                setisManualEntry(true)

                setAddress({
                    address_lookup_line_1: line_1,
                    address_lookup_line_2: line_2,
                    address_lookup_line_3: line_3,
                    address_lookup_post_town: post_town,
                    address_lookup_postcode: postcode,
                    address_lookup_uprn: uprn,
                })

            },
        }
        )
    }

    const toggleFormVisibility = () => {
        setisManualEntry(!isManualEntry);
        setFormKey((prevKey) => prevKey + 1); // Update formKey to force re-render 
    }

    useEffect(() => {
        if (formData.length === 0) {
            getaddressForm();
        }
        else{
            fetchAddress()
        }
        // eslint-disable-next-line
    }, [formData, isManualEntry]);

    return (
        <div>
            {loading ? (
                <div className="loaderClass">
                    <LoadingWithBackdropComponent />
                </div>
            ) : error ? (
                <Alert type="error" message={message} />
            ) : (
                <div className="addressFormContainer">
                    <p className={`text-small emerald-font ${isManualEntry===true ? "hidden" : ""}`}>
                        Use the address finder below to find your home.
                    </p>
                    <div className={`addressForm ${isManualEntry ? "hidden" : ""}`}>
                        <label className="text-large emerald-font">Search Address</label>
                        <CustomTextField id="addressInput" placeholder="Find your address" resetValue={isManualEntry} inputStyles="var(--emerald-color)" />
                        <a className="text-small emerald-font address_type" onClick={toggleFormVisibility}>
                            Enter Manually
                        </a>
                    </div>
                    <div id="hiddenfields" className={`addressForm ${isManualEntry ? "" : "hidden"}`}>
                        <a className="text-small emerald-font address_type" onClick={toggleFormVisibility}>
                            Search Again
                        </a>
                        {/* Rest of the fields */}
                        <CustomForm formData={formData} small={true} noLines={true} 
                        button_position_css='flex-row-wrap justify-end' 
                        handleSubmit={handleSubmit} formKey={formKey} labelStyles="text-standard emerald-font" inputStyles="var(--emerald-color)" buttonLabel={"Save"}/>
                    </div>
                </div>
            )}
        </div>
    );
    

}
