import React, {useEffect, useState} from "react";
import LoadingWithBackdropComponent from "../components/Common/Loader/LoadingWithBackdrop.component";
import useAuth from "./AuthProvider";
import {useNavigate, useLocation} from "react-router-dom";

const AuthenticationGuard = ({ component: Component, userType:userType, ...rest }) => {

    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const { user, loading, isAuthenticated, error, login, signUp, logout } = useAuth();
    const navigate = useNavigate();
    useEffect(() => {
        if (!loading && !isAuthenticated) {
            navigate(`/${userType}-auth`, { state: { pathname: location.pathname } });
        }
    }, [loading, isAuthenticated, navigate, userType]);

    if (!loading) {
        return <Component {...rest} />
       ;
    }
    return  <div><LoadingWithBackdropComponent/></div>;

};

export default AuthenticationGuard;
